<div class="row">
  <div class="col-md-12">
    <a routerLink="/home">
      <img src="../../assets/SaveBolt_Logo_Horizontal.png">
      <hr>
    </a>
  </div>
  <div class="col-md-20">
    <a routerLink="/calculate">
      <img src="../../assets/getStarted2.png">
    </a>
  </div>
</div>