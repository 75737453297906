export class EstimateData {
  JCILatLng = { lat: 43.12425457724792, lng: -87.93533900161587 };
  public address:string=''
  public lat:number=this.JCILatLng.lat
  public lng:number=this.JCILatLng.lng
  public numberOfFloors:Number=0
  public numberOfRooms:Number=0
  public buildingSqft:Number=0
  public roofSqft:Number=0
  public sunlightHrs:Number=0
  public estimate:any={}
  public propertyName:string=''
  public buildingImg=''
  public clear() {
    this.address=''
    this.numberOfFloors=0
    this.numberOfRooms=0
    this.buildingSqft=0
    this.roofSqft=0
    this.sunlightHrs=0
    this.estimate={}
    this.propertyName=''
    this.buildingImg=''
  }
}