import { Component, OnInit } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { Router } from '@angular/router';
import { estimateData } from '../app-variables';
@Component({
  selector: 'app-calculate',
  templateUrl: './calculate.component.html',
  styleUrls: ['./calculate.component.css']
})
export class CalculateComponent implements OnInit {

  constructor(private http: HttpClient, private router: Router) { }

  ngOnInit(): void { }
  display: any;

  userAddress: string = ''
  userLatitude: number = 0
  userLongitude: number = 0
  propertyName: string = ''
  isFormValid: boolean = true;
  isCalculating: boolean = false;
  //   estimate:any = {
  //     "reference-number": 56556,
  //     "property-address": "2484 Hotel Circle Pl, San Diego, CA 92108",
  //     "building-sqft": 50000,
  //     "num-rooms": 110,
  //     "num-floors": 4,
  //     "contact-email": "bullenp@zugproperties.com",
  //     "energy-baseline": {
  //         "total-annual-cost": 110000
  //     },
  //     "upgrades": [
  //         {
  //             "upgrade": "LED Lighting",
  //             "icon": "https://static.thenounproject.com/png/4530364-200.png",
  //             "annual-savings": 15000
  //         },
  //         {
  //             "upgrade": "Sensor Thermostat",
  //             "icon": "https://static.thenounproject.com/png/4773787-200.png",
  //             "annual-savings": 12000
  //         },
  //         {
  //             "upgrade": "Efficient PTAC Units",
  //             "icon": "https://static.thenounproject.com/png/1752143-200.png",
  //             "annual-savings": 12000
  //         },
  //         {
  //             "upgrade": "Dual-Pane Windows",
  //             "icon": "https://static.thenounproject.com/png/3307583-200.png",
  //             "annual-savings": 10000
  //         },
  //         {
  //             "upgrade": "Solar + Batteries",
  //             "icon": "https://static.thenounproject.com/png/4735349-200.png",
  //             "annual-savings": 10000
  //         }
  //     ]
  // };
  handleAddressChange(address: any) {
    this.userAddress = address.formatted_address
    this.userLatitude = address.geometry.location.lat()
    this.userLongitude = address.geometry.location.lng()
    this.propertyName = address.name

  }

  closeModal() {
    var modal = document.getElementById("myModal");
    if(modal != null)
    {
      modal.style.display = "none";
    }

    //return false;
  }

  calculate(inputNumberOfRooms: string, inputNumberOfFloors: string, inputEmail: string, inputPhone: string, inputName:string, inputCompany:string) {
    this.isFormValid = true
    if (this.userAddress && inputName && Number.parseInt(inputNumberOfRooms) && Number.parseInt(inputNumberOfFloors) && inputEmail && inputPhone) {
      estimateData.clear();
      estimateData.address = this.userAddress
      estimateData.lat = this.userLatitude
      estimateData.lng = this.userLongitude
      estimateData.propertyName = this.propertyName
      this.isCalculating = true

      // const url = `/api/v1/getProjects?numrooms=${inputNumberOfRooms},bldgsqft=${inputSqft},numfloors=${inputNumberOfFloors},address=${this.userAddress},sunlighthrs=notUsed,roofsqft=notUsed`
      const url = `/simulate?property-address=${this.userAddress}&num-rooms=${inputNumberOfRooms}&num-floors=${inputNumberOfFloors}&person-name=${inputName}&contact-email=${inputEmail}&phone-number=${inputPhone}&company-name=${inputCompany}`
      this.http.get(url).subscribe((data) => {

        console.log("test")
        console.log(data)

        this.isCalculating = false
        estimateData.estimate = data
        this.router.navigateByUrl('/report')
      }
      , err => {

            var email_address_span = document.getElementById("email_address_span");

            if(email_address_span != null)
            email_address_span.innerHTML = inputEmail
 
            // Get the modal
            var modal = document.getElementById("myModal");

            // Get the button that opens the modal
            var btn = document.getElementById("myBtn");

            // Get the <span> element that closes the modal
            var span = document.getElementsByClassName("close")[0];

            if(modal != null)
            modal.style.display = "block";
             
 
}
      );

    } else {
      this.isFormValid = false
    }
    setTimeout(() => this.isFormValid = true, 2000);
  }


}
