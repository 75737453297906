
  <div class="col-md-12">

<canvas baseChart
[data]="barChartData"
[options]="barChartOptions"
[plugins]="barChartPlugins"
[type]="barChartType"
(chartHover)="chartHovered($event)"
(chartClick)="chartClicked($event)">
</canvas>

</div>