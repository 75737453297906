import { Component, OnInit } from '@angular/core';
import { estimateData } from '../app-variables';
import { EstimateData } from '../estimateData';
import { Router } from '@angular/router';


@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.css']
})
export class ReportComponent implements OnInit {
  constructor(private router: Router) { }
  estimateData: EstimateData = estimateData
  ngOnInit(): void {
    if (!estimateData.propertyName) {
      this.router.navigateByUrl('/home')
    }
  }

  onScheduleCallMessage() {
    window.open("http://calendly.com/utility-saver?name=" + estimateData.estimate["person-name"] +
      "&email=" + estimateData.estimate["contact-email"] +
      "&a1=" + estimateData.estimate["contact-phone"],
      "_blank")
  }


}
