<div class="row">
  <div class="col-md-12">
    <a routerLink="/home">
      <img src="../../assets/SaveBolt_Logo_Horizontal.png">
      <hr>
    </a>
  </div>
</div>
<div class="row">
  <div class="col-md-6">
    <div class="row">
      <div class="col-md-12">
        <p class="es-prop-name">{{ estimateData.propertyName }}</p>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <p>{{ estimateData.estimate["property-address"] }}</p>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <p>Number of Rooms</p>
      </div>
      <div class="col-md-6">
        <p>{{ estimateData.estimate["num-rooms"] }}</p>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <p>Number of Floors</p>
      </div>
      <div class="col-md-6">
        <p>{{ estimateData.estimate["num-floors"] }}</p>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <p>Report Made For</p>
      </div>
      <div class="col-md-6">
        <p>{{ estimateData.estimate["person-name"] }}</p>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <p>Contact Phone</p>
      </div>
      <div class="col-md-6">
        <p>{{ estimateData.estimate["contact-phone"] }}</p>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <p>Contact Email</p>
      </div>
      <div class="col-md-6">
        <p>{{ estimateData.estimate["contact-email"] }}</p>
      </div>
    </div>


    <div class="row">
      <div class="col-md-6">
        <p>Company Name</p>
      </div>
      <div class="col-md-6">
        <p>{{ estimateData.estimate["company-name"] }}</p>
      </div>
    </div>


    <div class="row">
      <div class="col-md-6">
        <p>Report Reference ID</p>
      </div>
      <div class="col-md-6">
        <p>{{ estimateData.estimate["reference-number"] }}</p>
      </div>
    </div>
  </div>
  <div class="col-md-6">
    <img
      src="https://maps.googleapis.com/maps/api/staticmap?zoom=13&size=480x240&maptype=roadmap&markers=color:red|label:C|{{ estimateData.lat}},{{ estimateData.lng }}&key=AIzaSyAjEkr-FVqwnl2779qtvN2Lkqc_wmB4I58">
  </div>
</div>
<br>
<br>
<div class="row">
  <div class="cold-md-12">
    <p class="prop-header">Recommended $0 Down Upgrades</p>
  </div>
</div>
<div class="row">
  <p>OpenBlue Utility Saver by Johnson Controls provides an end-to-end service to identify upgrades with the
    <strong>highest paybacks and
      incentives</strong>. We <strong>contract</strong>, <strong>manage</strong>, and <strong>finance</strong> property
    upgrades that can collectively save a whopping 50%
    on your energy costs. Numbers below are estimates based on <strong>similar-sized hotels near your
      property</strong>.  We provide a <strong>free onsite assessment</strong> to identify savings for your property.
    Book a free assessment <strong>right now</strong> and get up to a <strong>$1,000 rebate</strong> on your first
    upgrade!
  </p>
</div>
<div class="row">
  <div class="cold-md-12">
    <hr class="prop-header">
  </div>
</div>
<div class="row justify-content-center align-items-center"
  *ngFor="let item of estimateData.estimate.upgrades; let i = index">
  <div class="col-md-2"><img src="{{ item.icon }}" style="width: 80px;"></div>
  <div class="col-md-5 upgrade-item">{{ item.upgrade }}</div>
  <div class="col-md-5 upgrade-item-saving"> <strong>{{ item["annual-savings"] | currency:'$':true: '1.0-0'
      }}</strong> Est. Annual Savings</div>
</div>
<br>
<div class="row">
  <div class="col-md-6 offset-md-5 ">
    <button class="btn btn-success" style="width: max-content;background-color: #235499"
      (click)="onScheduleCallMessage()">
      Schedule a Call to Learn More
    </button>
  </div>
</div>
<br>
<hr class="prop-header">
<div class="row footer-box">
  <div class="row">
    <div class="col-md-12">
      <br>
    </div>
  </div>
  <div class="row">
    <div class="col-md-10 offset-1 footer-note">
      Utility Saver savings estimates are based on our analysis of energy data from 6,000 hotels across the US, provided
      by the Dept of Energy. We use the data from the hotels most similar to your location and number of rooms to come
      up with recommended upgrades. The savings dollar amounts factor in energy time of use, lighting, heating and
      cooling, laundry, hot water use, as well as utility rate plans. Rebate amount is capped at our proceeds from the
      project.
    </div>
    <div class="row footer-note">
    </div>
  </div>
  <div class="row">
    <div class="col-md-12">
      <br>
    </div>
  </div>
</div>