<div class="container ">
  <div class="logo-container">
      <img src="../../assets/logo.png" style="max-width: 250px;"  />
  </div>
</div>


<div id="myModal" class="modal1">

  <!-- Modal content -->
  <div class="modal-content1">
   
    <h5>We are coming soon! We will reach out as soon as we are ready to support your location</h5>
    <p>We are expanding our reach and we can't wait to bring Utility Saver to your area very soon! While we are not quite there yet, we appreciate your patience and we're working hard to makeit happen.
      We will email you at <span id="email_address_span">something@something</span> as soon as we start supporting your location.
    </p>

    <p><a href="#" (click)="closeModal()" class="close btn btn-primary OKBTN">OK</a></p>
  </div>

</div>

 
<div class="container">
  <div class="row">
      <div class="col-12 col-md-7">
              <h1 class="main-tt">Save Big!</h1>
              <h2 class="sub-tt">Optimize your Operational Costs</h2><div class="after_h2"></div>

              <p class="p-tt">We can help you cut your utility bills up to 50% with property upgrades that pay for themselves!</p>
      </div>


      <div class="col-12 col-md-5">
          <div class="my-card">

  
            <div class="row" [hidden]="isFormValid" >
              <div class="col-md-12  " style="color: red;">
                <h3>Please enter valid values.</h3>
              </div>
            </div>

   
 
                  <div class="form-group mb-4">
                      <label for="exampleInputEmail1" class="mb-1">Hotel Name and Location</label>
                      <input class="form-control" ngx-google-places-autocomplete (onAddressChange)="handleAddressChange($event)" placeholder="Enter a location" />
                    </div>


                    <div class="row">
                      <div class="col-12 col-md-6  mb-4">
                          <label for="exampleInputEmail1" class="mb-1">Number of Floors</label>
                          <input type="text" class="form-control" id="inputNumberOfFloors" #inputNumberOfFloors></div>

                          <div class="col-12 col-md-6  mb-4">
                              <label for="exampleInputEmail1" class="mb-1">Number of Rooms</label>
                              <input type="text" class="form-control" id="inputNumberOfRooms" #inputNumberOfRooms></div>
                    </div>


                    <div class="form-group mb-4">
                      <label for="exampleInputEmail1" class="mb-1">Your Name</label>
                      <input type="text" class="form-control" id="inputName" #inputName>
                    </div>


                    <div class="form-group mb-4">
                      <label for="exampleInputEmail1" class="mb-1">Company Name</label>
                      <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp"  #inputCompany>
                    </div>


                    <div class="form-group mb-4">
                      <label for="exampleInputEmail1" class="mb-1">Email Address</label>
                      <input type="text" class="form-control" id="inputEmail" #inputEmail>
                    </div>


                    <div class="form-group mb-5">
                      <label for="exampleInputEmail1" class="mb-1">Phone Number</label>
                      <input type="text" class="form-control" id="inputPhone" #inputPhone>
                    </div>
  

                    <button class="btn btn-primary w-100" type="button"  style="padding-top:12px; padding-bottom:12px" disabled *ngIf="isCalculating" >
                      <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                      Calculating...
                    </button>
           
                  
                  <button type="button" class="btn btn-primary w-100" style="padding-top:12px; padding-bottom:12px" *ngIf="!isCalculating"
                  (click)="calculate(inputNumberOfRooms.value, inputNumberOfFloors.value, inputEmail.value, inputPhone.value, inputName.value, inputCompany.value)">Calculate my Savings</button>
           


              </div>

          
      </div>

  </div>

</div>



 